import { graphql } from 'gatsby';
//components
import { BlogsPanel } from '../components/BlogsPanel';
import { DoctorsSlide } from '../components/DoctorsSlide';
import { Technology } from '../components/Technology';
import { Testimonials } from '../components/Testimonials';
import { Seo } from '../components/Seo';
import { FAQ } from '../components/FAQ';
import { ContactForm } from '../components/layout/footer/ContactForm';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Blogs: Page<any> = ({ data }) => {
    return (
        <main>
            <Seo
                title="Dentist Tampa Florida | Blog artles – SmileTampa"
                description="An array of informative articles to read about our practice and general health"
            />
            <BlogsPanel mdx={data.allMdx} />
            <DoctorsSlide images={data.doctors.edges} />
            <ContactForm noForm />
            <FAQ />
            <Technology />
            <Testimonials />
        </main>
    );
};

export default Blogs;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(filter: { fields: { sourceInstanceName: { eq: "blogs" } } }) {
            nodes {
                frontmatter {
                    slug
                    title
                    type
                    categories
                    featuredImage {
                        childImageSharp {
                            gatsbyImageData(
                                width: 600
                                aspectRatio: 1.4
                                placeholder: BLURRED
                                quality: 90
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
                body
                excerpt
                slug
                id
            }
        }
        doctors: allFile(filter: { relativeDirectory: { eq: "doctorCards" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, formats: [AUTO, WEBP, JPG])
                    }
                    name
                }
            }
        }
    }
`;

Blogs.Layout = DefaultLayout;
